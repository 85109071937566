// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header_header__2t6Je {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px;
    height: 50px;
  
    color: var(--yello);

    background-image: linear-gradient(209deg, rgba(6, 56, 82, 1) 15%, rgba(9, 42, 59, 1) 72%);
    border-color: transparent;
    border-style: solid;
  }
  .Header_header_lk__CuEvi {
    margin: 15px;
    text-decoration: none;
    color: var(--yello);
    font-size: 20px;
  }
  .Header_header_title__RukAa {
    font-size: 30px;
    font-weight: 700;
  }
  @media (max-width: 360px) {
    .Header_header_title__RukAa {
      font-size: 20px;
    }  
    .Header_header_lk__CuEvi {
      margin: 15px;
      font-size: 18px;
    }
  }`, "",{"version":3,"sources":["webpack://./src/styles/Header.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,kBAAkB;IAClB,YAAY;;IAEZ,mBAAmB;;IAEnB,yFAAyF;IACzF,yBAAyB;IACzB,mBAAmB;EACrB;EACA;IACE,YAAY;IACZ,qBAAqB;IACrB,mBAAmB;IACnB,eAAe;EACjB;EACA;IACE,eAAe;IACf,gBAAgB;EAClB;EACA;IACE;MACE,eAAe;IACjB;IACA;MACE,YAAY;MACZ,eAAe;IACjB;EACF","sourcesContent":[".header {\n    width: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    padding: 15px 20px;\n    height: 50px;\n  \n    color: var(--yello);\n\n    background-image: linear-gradient(209deg, rgba(6, 56, 82, 1) 15%, rgba(9, 42, 59, 1) 72%);\n    border-color: transparent;\n    border-style: solid;\n  }\n  .header_lk {\n    margin: 15px;\n    text-decoration: none;\n    color: var(--yello);\n    font-size: 20px;\n  }\n  .header_title {\n    font-size: 30px;\n    font-weight: 700;\n  }\n  @media (max-width: 360px) {\n    .header_title {\n      font-size: 20px;\n    }  \n    .header_lk {\n      margin: 15px;\n      font-size: 18px;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `Header_header__2t6Je`,
	"header_lk": `Header_header_lk__CuEvi`,
	"header_title": `Header_header_title__RukAa`
};
export default ___CSS_LOADER_EXPORT___;
