// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --yello: #97ff29;
    --text-white: #f6f6f7;
    --text-dark: #063852;
    --empty: orange;

    --bg-page: #011a27;
    --bg-footer: linear-gradient(225deg, rgba(6, 56, 82, 1) 15%, rgba(9, 42, 59, 1) 72%);
    --bg-post: linear-gradient(152deg, rgba(130, 227, 32, 1) 16%, rgba(191, 255, 127, 1) 84%);
    --bg-button: #7f29ff;
    --btn-hover: #6c3eb8;

    --notactive:  rgb(215, 215, 215);
}
`, "",{"version":3,"sources":["webpack://./src/styles/variables.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,qBAAqB;IACrB,oBAAoB;IACpB,eAAe;;IAEf,kBAAkB;IAClB,oFAAoF;IACpF,yFAAyF;IACzF,oBAAoB;IACpB,oBAAoB;;IAEpB,gCAAgC;AACpC","sourcesContent":[":root {\n    --yello: #97ff29;\n    --text-white: #f6f6f7;\n    --text-dark: #063852;\n    --empty: orange;\n\n    --bg-page: #011a27;\n    --bg-footer: linear-gradient(225deg, rgba(6, 56, 82, 1) 15%, rgba(9, 42, 59, 1) 72%);\n    --bg-post: linear-gradient(152deg, rgba(130, 227, 32, 1) 16%, rgba(191, 255, 127, 1) 84%);\n    --bg-button: #7f29ff;\n    --btn-hover: #6c3eb8;\n\n    --notactive:  rgb(215, 215, 215);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
