// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../images/arrow-up-30.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../images/arrow-up-30-yello.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ScrollTopBottom_up__Q7oB6, .ScrollTopBottom_down__\\+TwOY{
    width: 30px;
    height: 30px;
    z-index: 2;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    position: absolute;
    right: 25%;
    bottom: 28%;
}

.ScrollTopBottom_up__Q7oB6:hover{
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}
.ScrollTopBottom_down__\\+TwOY{
    transform: rotate(180deg);
}

.ScrollTopBottom_down__\\+TwOY:hover{
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
    transform: rotate(180deg);
}`, "",{"version":3,"sources":["webpack://./src/styles/ScrollTopBottom.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,UAAU;IACV,yDAAgD;IAChD,kBAAkB;IAClB,UAAU;IACV,WAAW;AACf;;AAEA;IACI,yDAAsD;AAC1D;AACA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yDAAsD;IACtD,yBAAyB;AAC7B","sourcesContent":[".up, .down{\n    width: 30px;\n    height: 30px;\n    z-index: 2;\n    background-image: url(../images/arrow-up-30.png);\n    position: absolute;\n    right: 25%;\n    bottom: 28%;\n}\n\n.up:hover{\n    background-image: url(../images/arrow-up-30-yello.png);\n}\n.down{\n    transform: rotate(180deg);\n}\n\n.down:hover{\n    background-image: url(../images/arrow-up-30-yello.png);\n    transform: rotate(180deg);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"up": `ScrollTopBottom_up__Q7oB6`,
	"down": `ScrollTopBottom_down__+TwOY`
};
export default ___CSS_LOADER_EXPORT___;
